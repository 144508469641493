import logo from './gslLogo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{ margin: 10 }}>
          GSL Group Server
        </p>
        <p style={{ margin: 10 }}>
          Click below to visit our production sites
        </p>
        <a
          className="App-link"
          href="https://gslwhitsundays.com.au/"
          rel="noopener"
          style={{ marginTop: 20 }}
        >
          GSL Whitsundays
        </a>
        <a 
          className="App-link"
          href="https://gslaviation.com.au/"
          rel="noopener"
        >
          GSL Aviation
        </a>
        <a 
          className="App-link"
          href="https://gslmarine.com.au/"
          rel="noopener"
        >
          GSL Marine
        </a>
        <a 
          className="App-link"
          href="https://airwhitsunday.com.au/"
          rel="noopener"
        >
          Air Whitsunday
        </a>
      </header>
    </div>
  );
}

export default App;
